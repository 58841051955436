@import "global/variables";

html, body {
  height: 100%;
  margin: 0;
  width: 100%; }

body {
  background: black;
  color: white;
  font-family: sans-serif; }

// add this class if you just want to use ng-show/hide without any animation,
// otherwise ng-animate will fuck it up (https://github.com/angular/angular.js/issues/8224)
.no-animate, .no-animate.ng-animate {
  animation: none 0s !important;
  transition-duration: 0s !important; }

[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none; }

// Unsupported

.un {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  padding: 40px 0;
  width: 90%;

  a {
    color: white; } }

.supported .un {
  display: none; }

.un-title {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center; }

.un-body {
  margin: 1em 0; }

.un-link {
  text-align: center;
  a {
    display: inline-block;
    padding: 1em;
    text-decoration: none;
    transition: background-color 200ms;

    // .no-touch &:hover
 } }    //   background: $helios-orange
