
/**
 * Colours
 */
$colour__light: #fff;
$colour__dark: #000;

$colour__red: #cd0401;
$colour__green: #185e22;
$colour__green--light: #8ac20d;
$colour__blue: #569ec4;
$colour__orange: #ff9000;

$colour__primary: $colour__red;
$colour__secondary: $colour__green;

$colour__text: #666666;
$colour__text--muted: lighten($colour__text, 40%);

$colour__link: $colour__red;
$colour__link--hover: darken($colour__red, 10%);

$colour__border: $colour__text--muted;

/**
 * Layout
 */
$sidebar__width: 360px;
$spacing__vert: 40px;

/**
 * Switch slice
 */
$switchslice__width: 40px;

/**
 * Streetview positioning
 */
$map__height:          120px;
$map__width:           200px;
$map__position--horz:  5px;
$map__position--vert:  20px;

/**
 * Type
 */
$font-family__sans: 'Roboto', sans-serif;
$font-family__cond: 'Roboto Condensed', sans-serif;

$font-size__base: 14px;
$font-size__small: 12px;

$line-height__base: 1.3;

$font-weight__bold: 700;
